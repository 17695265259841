'use client'

import NextImage, { ImageProps } from 'next/image'
import { forwardRef } from 'react'

import type { Ref } from 'react'

export const Image = forwardRef(function Image(
  props: ImageProps,
  ref: Ref<HTMLImageElement> | undefined,
) {
  return <NextImage ref={ref} {...props} unoptimized />
})
