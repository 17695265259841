import { Domain, StoreCodeRecord } from '@/common/types'
import { Language } from '../types/language-types'
import { CurrencyEnum } from '../api/graphql'

export const STORE_CODE_RECORD: StoreCodeRecord = {
  gymbeamcom: {
    storeCode: 'gymbeamcom',
    name: 'GymBeamCom',
    lang: Language.En,
    locale: 'en-US',
    metadataTitle: 'GymBeam.com',
    gtmId: 'GTM-NSK68NRK',
    socials: {
      instagram: '#',
      facebook: 'https://www.facebook.com/GymBeamOfficial',
      youtube: 'https://www.youtube.com/channel/UC9M_575OJL_r1KVcaZU-ANA',
      tiktok: 'https://www.tiktok.com/@gymbeam.com',
      linkedin: 'https://www.linkedin.com/company/gym-beam/',
      twitter: 'https://twitter.com/GymBeamTeam',
    },
    domain: Domain.COM,
    currency: CurrencyEnum.Eur,
    label: 'General Store',
    language: 'EN',
  },
  gymbeamsk: {
    storeCode: 'gymbeamsk',
    name: 'GymBeamSk',
    lang: Language.Sk,
    locale: 'sk-SK',
    metadataTitle: 'GymBeam.sk',
    gtmId: 'GTM-TRKS76J',
    socials: {
      instagram: 'https://www.instagram.com/gymbeamsk/',
      facebook: 'https://www.facebook.com/GymBeamSk',
      youtube: 'https://www.youtube.com/c/GymbeamSk',
      tiktok: 'https://www.instagram.com/gymbeamsk/',
      linkedin: 'https://www.linkedin.com/company/gym-beam/',
      twitter: 'https://twitter.com/GymBeamTeam',
    },
    domain: Domain.SK,
    currency: CurrencyEnum.Eur,
    label: 'Slovakia',
  },
  gymbeamcz: {
    storeCode: 'gymbeamcz',
    name: 'GymBeamCz',
    lang: Language.Cs,
    locale: 'cs-CZ',
    metadataTitle: 'GymBeam.cz',
    gtmId: 'GTM-TTRMRPM',
    socials: {
      instagram: 'https://www.instagram.com/gymbeamcz/',
      facebook: 'https://www.facebook.com/GymBeamCz/',
      youtube: 'https://www.youtube.com/c/GymbeamCz',
      tiktok: 'https://www.tiktok.com/@gymbeam.com',
      linkedin: 'https://www.linkedin.com/company/gym-beam/',
      twitter: 'https://twitter.com/GymBeamTeam',
    },
    domain: Domain.CZ,
    currency: CurrencyEnum.Czk,
    label: 'Czechia',
  },
  gymbeamhu: {
    storeCode: 'gymbeamhu',
    name: 'GymBeamHu',
    lang: Language.Hu,
    locale: 'hu-HU',
    metadataTitle: 'GymBeam.hu',
    gtmId: 'GTM-WN6FD39',
    socials: {
      instagram: 'https://www.instagram.com/gymbeamhu/',
      facebook: 'https://www.facebook.com/GymBeamHu',
      youtube: 'https://www.youtube.com/channel/UCPSy0DOJ7F58J3DunI1HGsQ/',
      tiktok: 'https://www.tiktok.com/@gymbeam.com',
      linkedin: 'https://www.linkedin.com/company/gym-beam/',
      twitter: 'https://twitter.com/GymBeamTeam',
    },
    domain: Domain.HU,
    currency: CurrencyEnum.Huf,
    label: 'Hungary',
  },
  gymbeamro: {
    storeCode: 'gymbeamro',
    name: 'GymBeamRo',
    lang: Language.Ro,
    locale: 'ro-RO',
    metadataTitle: 'GymBeam.ro',
    gtmId: 'GTM-NG393QM',
    socials: {
      instagram: 'https://www.instagram.com/gymbeamro/',
      facebook: 'https://www.facebook.com/GymBeamRo/',
      youtube: 'https://www.youtube.com/c/GymbeamRo',
      tiktok: 'https://www.tiktok.com/@gymbeam.com',
      linkedin: 'https://www.linkedin.com/company/gym-beam/',
      twitter: 'https://twitter.com/GymBeamTeam',
    },
    domain: Domain.RO,
    currency: CurrencyEnum.Ron,
    label: 'Romania',
  },
  gymbeamde: {
    storeCode: 'gymbeamde',
    name: 'GymBeamDe',
    lang: Language.De,
    locale: 'de-DE',
    metadataTitle: 'GymBeam.de',
    gtmId: 'GTM-NSK68NRK',
    socials: {
      instagram: '#',
      facebook: 'https://www.facebook.com/GymBeamOfficial',
      youtube: 'https://www.youtube.com/channel/UC9M_575OJL_r1KVcaZU-ANA',
      tiktok: 'https://www.tiktok.com/@gymbeam.com',
      linkedin: 'https://www.linkedin.com/company/gym-beam/',
      twitter: 'https://twitter.com/GymBeamTeam',
    },
    domain: Domain.DE,
    currency: CurrencyEnum.Eur,
    label: 'Germany',
  },
  gymbeamhr: {
    storeCode: 'gymbeamhr',
    name: 'GymBeamHr',
    lang: Language.Hr,
    locale: 'hr-HR',
    metadataTitle: 'GymBeam.hr',
    gtmId: 'GTM-NSK68NRK',
    socials: {
      instagram: 'https://www.instagram.com/gymbeamhr/',
      facebook: 'https://www.facebook.com/GymBeamHr/',
      youtube: 'https://www.youtube.com/c/GymbeamHr',
      tiktok: 'https://www.tiktok.com/@gymbeam.com',
      linkedin: 'https://www.linkedin.com/company/gym-beam/',
      twitter: 'https://twitter.com/GymBeamTeam',
    },
    domain: Domain.HR,
    currency: CurrencyEnum.Eur,
    label: 'Croatia',
  },
  gymbeambg: {
    storeCode: 'gymbeambg',
    name: 'GymBeamBg',
    lang: Language.Bg,
    locale: 'bg-BG',
    metadataTitle: 'GymBeam.bg',
    gtmId: 'GTM-NSK68NRK',
    socials: {
      instagram: 'https://www.instagram.com/gymbeambg/',
      facebook: 'https://www.facebook.com/GymBeamBg',
      youtube: 'https://www.youtube.com/channel/UCIGJXp5yivxIEoUesDgNmRg',
      tiktok: 'https://www.tiktok.com/@gymbeam.com',
      linkedin: 'https://www.linkedin.com/company/gym-beam/',
      twitter: 'https://twitter.com/GymBeamTeam',
    },
    domain: Domain.BG,
    currency: CurrencyEnum.Bgn,
    label: 'Bulgaria',
  },
  gymbeampl: {
    storeCode: 'gymbeampl',
    name: 'GymBeamPl',
    lang: Language.Pl,
    locale: 'pl-PL',
    metadataTitle: 'GymBeam.pl',
    gtmId: 'GTM-K83HQ64',
    socials: {
      instagram: 'https://www.instagram.com/gymbeampl/',
      facebook: 'https://www.facebook.com/GymBeamPL/',
      youtube: 'https://www.youtube.com/channel/UCBCqGnINx9KOQly38nBQUWg',
      tiktok: 'https://www.tiktok.com/@gymbeam.com',
      linkedin: 'https://www.linkedin.com/company/gym-beam/',
      twitter: 'https://twitter.com/GymBeamTeam',
    },
    domain: Domain.PL,
    currency: CurrencyEnum.Pln,
    label: 'Poland',
  },
  gymbeamsi: {
    storeCode: 'gymbeamsi',
    name: 'GymBeamSi',
    lang: Language.Sl,
    locale: 'sl-SI',
    metadataTitle: 'GymBeam.si',
    gtmId: 'GTM-NSK68NRK',
    socials: {
      instagram: 'https://www.instagram.com/gymbeam.si/',
      facebook: 'https://www.facebook.com/gymbeam.si/',
      youtube:
        'https://www.youtube.com/channel/UCbtdQje12yi4CX1jRONyp4A?sub_confirmation=1',
      tiktok: 'https://www.tiktok.com/@gymbeam.com',
      linkedin: 'https://www.linkedin.com/company/gym-beam/',
      twitter: 'https://twitter.com/GymBeamTeam',
    },
    domain: Domain.SI,
    currency: CurrencyEnum.Eur,
    label: 'Slovenia',
  },
  gymbeamba: {
    storeCode: 'gymbeamba',
    name: 'Bosnian',
    lang: Language.Bs,
    locale: 'bs-Latn-BA',
    metadataTitle: 'GymBeam.ba',
    gtmId: 'GTM-NSK68NRK',
    socials: {
      instagram: 'http://instagram.com/gymbeamba/',
      facebook: 'https://www.facebook.com/gymbeamBa/',
      youtube: 'https://www.youtube.com/gymbeamHr',
      tiktok: 'https://www.tiktok.com/@gymbeam.com',
      linkedin: 'https://www.linkedin.com/company/gym-beam/',
      twitter: 'https://twitter.com/GymBeamTeam',
    },
    domain: Domain.BA,
    currency: CurrencyEnum.Bam,
    label: 'Bosnia & Herzegovina',
  },
  gymbeamrs: {
    storeCode: 'gymbeamrs',
    name: 'Serbian',
    lang: Language.Sr,
    locale: 'sr-Cyrl-RS',
    metadataTitle: 'GymBeam.rs',
    gtmId: 'GTM-NSK68NRK',
    socials: {
      instagram: 'https://www.instagram.com/gymbeamsrb/',
      facebook: 'https://www.facebook.com/gymbeamRs/',
      youtube:
        'https://www.youtube.com/channel/UCU8HZvc93ovPPM41moAhl7g/featured',
      tiktok: 'https://www.tiktok.com/@gymbeam.com',
      linkedin: 'https://www.linkedin.com/company/gym-beam/',
      twitter: 'https://twitter.com/GymBeamTeam',
    },
    domain: Domain.RS,
    currency: CurrencyEnum.Rsd,
    label: 'Serbia',
  },
  gymbeamgr: {
    storeCode: 'gymbeamgr',
    name: 'GymBeamGr',
    lang: Language.El,
    locale: 'el-GR',
    metadataTitle: 'GymBeam.gr',
    gtmId: 'GTM-NSK68NRK',
    socials: {
      instagram: 'https://www.instagram.com/gymbeamgr/',
      facebook: 'https://www.facebook.com/gymbeam.gr/',
      youtube: 'https://www.youtube.com/channel/UCjs6E90iU4Fcf76jcVd_YlQ',
      tiktok: 'https://www.tiktok.com/@gymbeam.com',
      linkedin: 'https://www.linkedin.com/company/gym-beam/',
      twitter: 'https://twitter.com/GymBeamTeam',
    },
    domain: Domain.GR,
    currency: CurrencyEnum.Eur,
    label: 'Greece',
  },
  gymbeamit: {
    storeCode: 'gymbeamit',
    name: 'GymBeamIt',
    lang: Language.It,
    locale: 'it-IT',
    metadataTitle: 'GymBeam.it',
    gtmId: 'GTM-NSK68NRK',
    socials: {
      instagram: '#',
      facebook: 'https://www.facebook.com/GymBeamOfficial',
      youtube: 'https://www.youtube.com/channel/UC9M_575OJL_r1KVcaZU-ANA',
      tiktok: 'https://www.tiktok.com/@gymbeam.com',
      linkedin: 'https://www.linkedin.com/company/gym-beam/',
      twitter: 'https://twitter.com/GymBeamTeam',
    },
    domain: Domain.IT,
    currency: CurrencyEnum.Eur,
    label: 'Italy',
  },
  ua: {
    storeCode: 'ua',
    name: 'UA',
    lang: Language.Uk,
    locale: 'uk-UA',
    metadataTitle: 'GymBeam.ua',
    ogSiteName: 'GymBeam.uaua',
    gtmId: 'GTM-NSK68NRK',
    socials: {
      instagram: 'https://www.instagram.com/gymbeamua/',
      facebook: 'https://www.facebook.com/GymBeamUA/',
      youtube: 'https://www.youtube.com/channel/UCsTm0UxgqWG4i4VEE6h_oAg',
      tiktok: 'https://www.tiktok.com/@gymbeam.com',
      linkedin: 'https://www.linkedin.com/company/gym-beam/',
      twitter: 'https://twitter.com/GymBeamTeam',
    },
    domain: Domain.UA,
    currency: CurrencyEnum.Uah,
    label: 'Ukraine',
    language: 'UA',
  },
  ru: {
    storeCode: 'ru',
    name: 'RU',
    lang: Language.Ru,
    locale: 'ru-RU',
    metadataTitle: 'GymBeam.ua',
    ogSiteName: 'GymBeam.uaru',
    gtmId: 'GTM-NSK68NRK',
    socials: {
      instagram: 'https://www.instagram.com/gymbeamua/',
      facebook: 'https://www.facebook.com/GymBeamUA/',
      youtube: 'https://www.youtube.com/channel/UCsTm0UxgqWG4i4VEE6h_oAg',
      tiktok: 'https://www.tiktok.com/@gymbeam.com',
      linkedin: 'https://www.linkedin.com/company/gym-beam/',
      twitter: 'https://twitter.com/GymBeamTeam',
    },
    domain: Domain.RU,
    currency: CurrencyEnum.Uah,
    label: '',
    language: 'RU',
  },
}
