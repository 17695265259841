import React, { FC, HTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

const Paragraph: FC<HTMLAttributes<HTMLParagraphElement>> = (props) => {
  const { children, className, ...rest } = props

  return (
    <p className={twMerge('font-normal', className)} {...rest}>
      {children}
    </p>
  )
}

Paragraph.displayName = 'Paragraph'
export { Paragraph }
