export enum Language {
  En = 'en',
  Sk = 'sk',
  Cs = 'cs',
  Hu = 'hu',
  Ro = 'ro',
  De = 'de',
  Hr = 'hr',
  Bg = 'bg',
  Pl = 'pl',
  Sl = 'sl',
  Bs = 'bs',
  Sr = 'sr',
  El = 'el',
  It = 'it',
  Uk = 'uk',
  Ru = 'ru',
}
